import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { PersonGridObject } from '@it2go/types';
import { filterFrag } from './types/filter.types';
import { personGridItemFrag } from './types/person.types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class PersonGqlServiceV2 extends GqlServiceV2 {

    public getPersons(input: GridInput | null) {
        return this.query<PersonGridObject>(
            `
                query($input: GridInput) {
                    person {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${personGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
