import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, fromEvent, of, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BreadcrumbObject } from 'xng-breadcrumb/lib/types/breadcrumb.config';
import { handleRouteRefreshOn } from '../../modules/shared/helpers/route.helper';
import { EffectFactory } from '../../modules/shared/store/common/effect.factory';
import { AuthorizationService } from '../../services/authorization.service';
import { NavigatorService } from '../../services/navigator.service';
import * as actions from './global.actions';
import { selectCurrentUserId } from './global.selectors';
import { NotificationService } from '../../services/notification.service';
import { eventReceived } from './event';
import { pageWithoutLayout } from '../../app.utils';
import { SettingsService } from '../../services/settings.service';
import { ignore, setCurrentOrganizationId } from './global.actions';

@Injectable()
export class GlobalEffects {
    private factory = new EffectFactory(
        this.actions$,
        actions,
        this.authorizationService,
    ).create();
    private navigatorFactory = new EffectFactory(
        this.actions$,
        actions,
        this.navigatorService,
    ).create();

    getRules$ = this.factory.getRules;
    whoami$ = this.factory.whoami;
    getVisitHistory$ = this.navigatorFactory.getVisitHistory;

    changeLanguage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.setLanguage),
                map(({ language }) => this.translate.use(language)),
            ),
        { dispatch: false },
    );

    setSelectedOrganization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.setCurrentOrganizationId),
            tap(async ({ organizationId }) => {
                if (organizationId) {
                    localStorage.setItem(
                        'selectedOrganizationId',
                        organizationId.toString(),
                    );
                    await firstValueFrom(
                        this.settingsService.setSelectedOrganizationId({
                            organizationId: organizationId,
                            userId: '',
                        }),
                    );
                    await handleRouteRefreshOn(
                        'organizationChange',
                        this.router,
                        this.route,
                    );
                }
            }),
            map((organizationId) =>
                actions.globalSuccess({
                    input: { key: 'organizationChange', data: organizationId },
                }),
            ),
        ),
    );

    routeChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            withLatestFrom(this.breadcrumb.breadcrumbs$),
            switchMap(([{ payload }, breadcrumbs]) => {
                if (window.history.state.fromHistory) {
                    return of(actions.ignore());
                }
                const url = (payload as any)?.event?.urlAfterRedirects;
                if (pageWithoutLayout(url) || url == '/') {
                    return of(actions.ignore());
                }

                return this.navigatorService
                    .createVisitHistory({
                        url,
                        data: this.parseBreadcrumbsParams(breadcrumbs),
                        // Nevím, prostě nějaká ignore akce, abych zavřel hubu Angularu
                    })
                    .pipe(map(() => actions.ignore()));
            }),
        ),
    );

    updateVisitHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateVisitHistoryData),
            withLatestFrom(this.breadcrumb.breadcrumbs$),
            switchMap(([action, breadcrumbs]) => {
                if (window.history.state.fromHistory)
                    return of(actions.ignore());
                return this.navigatorService
                    .createVisitHistory({
                        url: this.router.url,
                        data: this.parseBreadcrumbsParams(breadcrumbs),
                    })
                    .pipe(map(() => actions.ignore()));
            }),
        ),
    );

    watchVisitHistory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.watchVisitHistory),
                withLatestFrom(this.store.select(selectCurrentUserId)),
                switchMap(([action, userId]) =>
                    this.navigatorService
                        .visitHistorySubscription(userId || '')
                        .pipe(
                            map((visitHistory) =>
                                actions.watchVisitHistoryReceived({
                                    visitHistory,
                                }),
                            ),
                            catchError((error) => of(actions.ignore())),
                        ),
                ),
            ),
        {},
    );

    watchEvents$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.watchEvents),
                switchMap(() =>
                    this.notificationService.eventSubscription().pipe(
                        map((event) => {
                            eventReceived.next(event.name);
                            return actions.ignore();
                        }),
                        catchError(() => of(actions.ignore())),
                    ),
                ),
            ),
        {},
    );

    postMessage$ = createEffect(() =>
        fromEvent(window, 'message').pipe(
            map((event) => {
                function parseJwt(token: string) {
                    const base64Url = token.split('.')[1];
                    const base64 = base64Url
                        .replace(/-/g, '+')
                        .replace(/_/g, '/');
                    const jsonPayload = decodeURIComponent(
                        window
                            .atob(base64)
                            .split('')
                            .map(function (c) {
                                return (
                                    '%' +
                                    ('00' + c.charCodeAt(0).toString(16)).slice(
                                        -2,
                                    )
                                );
                            })
                            .join(''),
                    );

                    return JSON.parse(jsonPayload);
                }
                const token = (event as any as { data: { token: string } }).data
                    .token;
                if (!token) return ignore();
                const parsedToken = parseJwt(token);
                return setCurrentOrganizationId({
                    organizationId: parsedToken.id_organization,
                    token,
                });
            }),
        ),
    );

    private parseBreadcrumbsParams(breadcrumbs: BreadcrumbObject[]) {
        const params: Record<number, string> = {};
        breadcrumbs.forEach((it, index) => {
            const parts = (it?.label?.toString() || '').split('|');
            if (parts.length > 1) {
                params[index] = parts[1];
            } else {
                params[index] = `__${parts[0]}`;
            }
        });

        // CreateWork exception
        if (params[1] == '__works') {
            params[3] = params[2];
            params[2] = '';
        }

        return JSON.stringify(params);
    }

    constructor(
        private actions$: Actions,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private authorizationService: AuthorizationService,
        private navigatorService: NavigatorService,
        private notificationService: NotificationService,
        private settingsService: SettingsService,
        private store: Store,
        private breadcrumb: BreadcrumbService,
    ) {
        window.parent.postMessage('TOKEN', '*');
    }
}
