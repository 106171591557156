import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { FormBuilder } from '@angular/forms';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../config/config';
import { SearchStore } from './search.store';

@Component({
    selector: 'app-search',
    templateUrl: 'search.component.html',
    styleUrl: 'search.component.scss',
    providers: [SearchStore],
})
export class SearchComponent extends SubscriptionComponent implements OnDestroy {
    @ViewChild('search') inputElement!: ElementRef;

    protected activeIndex = 0;
    protected activeIndexPages = ['all', ...this.store.searchTabs, 'history'];
    protected loadingNext = false;
    protected form = this.fb.group({
        search: this.fb.control(''),
    });

    private timeout?: ReturnType<typeof setTimeout>;

    constructor(
        protected readonly store: SearchStore,
        private readonly layoutService: LayoutService,
        private readonly fb: FormBuilder,
    ) {
        super();
        this.subs.push(
            this.store.searchTabsSubj.subscribe((tabs) => {
                this.activeIndexPages = ['all', ...tabs, 'history'];
            }),
            this.form.controls.search.valueChanges.pipe(
                debounceTime(config.debounceTime),
            ).subscribe((search) => {
                if (!search?.length) return;
                this.store.clear();
                this.loadingNext = false;
                this.store.nextPage(this.activeIndexPages[this.activeIndex], search || '');
            }),
            this.store.data.results$.subscribe(() => {
                this.loadingNext = false;
            }),
            this.store.data.historyResults$.subscribe(() => {
                this.loadingNext = false;
            }),
        );
    }

    get visible(): boolean {
        return this.layoutService.state.searchBarActive;
    }

    set visible(_val: boolean) {
        this.layoutService.state.searchBarActive = _val;
    }

    public hide() {
        this.visible = false;
    }

    public override ngOnDestroy(): void {
        if (this.timeout) clearTimeout(this.timeout);
    }

    protected lazyLoad(): void {
        if (!this.form.value.search?.length) return;
        this.store.nextPage(this.activeIndexPages[this.activeIndex], this.form.value.search || '', true);
    }

    protected loadNext(entity: string = 'all'): void {
        this.loadingNext = true;
        this.store.nextPage(entity, this.form.value.search || '');
    }

    protected focusOnInput(): void {
        (this.inputElement as any).focus();
    }
}
