import { FormGroup } from '@angular/forms';

declare module '@angular/forms' {
    interface FormGroup {
        isInvalid(): boolean;
    }
}

Object.defineProperty(FormGroup.prototype, 'isInvalid', {
    value: function isInvalid(): boolean {
        this.markAllAsTouched();
        return !this.valid;
    },
    writable: true,
    configurable: true,
});
