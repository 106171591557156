import { Injectable } from '@angular/core';
import { filterFrag } from './types/filter.types';
import { GridInput } from '@it2go/data-grid';
import { serviceGroupItemFrag } from './types/service-settings.types';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { ServiceGroupGridObject } from '@libs/types/src/services/object-type/service/service-group.object';

@Injectable()
export class ServiceSettingsGqlServiceV2 extends GqlServiceV2 {

    public getServiceGroups(input: GridInput) {
        return this.query<ServiceGroupGridObject>(
            `
                query($input: GridInput) {
                    serviceGroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceGroupItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
