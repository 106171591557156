import { createReducer } from '@ngrx/store';
import * as actions from './notifications.actions';
import { createInitialNotificationsState } from './notifications.state';
import { ReducerFactory } from '../../../shared/store/common/reducer.factory';

const factory = new ReducerFactory(actions);

export const notificationsReducer = createReducer(
    createInitialNotificationsState(),

    ...factory.create(
        'getNotificationTypes',
        'getNotifications',
    ),
);
