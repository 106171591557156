import { BehaviorSubject } from 'rxjs';

declare module 'rxjs' {
    interface BehaviorSubject<T> {
        currentValue(): Promise<T>;
    }
}

Object.defineProperty(BehaviorSubject.prototype, 'currentValue', {
    value: async function currentValue<T>(): Promise<T> {
        await new Promise(resolve => setTimeout(resolve, 0));
        return this.value;

    },
    writable: true,
    configurable: true,
});
