import { Injectable } from '@angular/core';
import { AppointmentFieldsInputType } from '@libs/types/src/scheduler/input-type/scheduler.input-type';
import {
    schedulerAppointmentFrag,
    schedulerVoucherPrescribedMedicalProceduresFrag,
} from './types/scheduler.gql-types';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import { AppointmentGridObject } from '@libs/types/src/scheduler/object-type/scheduler.object-type';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class SchedulerGqlServiceV2 extends GqlServiceV2 {

    public schedulerBatchUpdate(input: AppointmentFieldsInputType, fragment = schedulerAppointmentFrag) {
        return this.mutate(
            `
                mutation($input: AppointmentFieldsInputType!) {
                    scheduler {
                        batchUpdate(input: $input) {
                            ${fragment}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getSchedulerEvents(input: GridInput | null = null, fragment = schedulerAppointmentFrag) {
        return this.query<AppointmentGridObject>(
            `
                query($input: GridInput) {
                    scheduler {
                        getEvents(input: $input) {
                            ${filterFrag}
                            items {
                                ${fragment}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getSchedulerVoucherPrescribedMedicalProcedures(input: GridInput, fragment = schedulerVoucherPrescribedMedicalProceduresFrag) {
        return this.query(
            `
                query($input: GridInput) {
                    voucherPrescribedMedicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${fragment}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
