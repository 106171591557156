import { Injectable } from '@angular/core';
import {
    EmploymentObject,
    EmploymentTypeGridObject,
    OkObject,
} from '@it2go/types';
import { employmentFrag, employmentGridFrag, employmentTypeFrag } from './types/user.types';
import { CreateEmploymentInput } from '@libs/types/src/user/input-type/create-employment.input';
import { filterFrag } from './types/filter.types';
import { GridInput } from '@it2go/data-grid';
import { EmploymentGridObject } from '@libs/types/src/user/object-type/employment-grid.object';
import { DeleteEmploymentInput } from '@libs/types/src/user/input-type/delete-employment.input';
import { UpdateEmploymentInput } from '@libs/types/src/user/input-type/update-employment.input';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class EmploymentGqlService extends GqlServiceV2 {

    createEmployment(input: CreateEmploymentInput) {
        return this.mutate<EmploymentObject>(
            `
                mutation($input: CreateEmploymentInput!) {
                    employment {
                        create(input: $input) {
                            ${employmentFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateEmployment(input: UpdateEmploymentInput) {
        return this.mutate<EmploymentObject>(
            `
                mutation($input: UpdateEmploymentInput!) {
                    employment {
                        update(input: $input) {
                            ${employmentFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getAllEmploymentTypes() {
        return this.query<EmploymentTypeGridObject>(
            `
                query {
                    employmentType {
                        all {
                            ${filterFrag}
                            items {
                                ${employmentTypeFrag}
                            }
                        }
                    }
                }
            `,
        );
    }

    public getEmployments(input: { input: GridInput, profile: boolean }) {
        return this.query<EmploymentGridObject>(
            `
                query($input: GridInput) {
                    employment {
                        ${input.profile ? 'userProfileFilter' : 'filter'}(input: $input) {
                            ${filterFrag}
                            items {
                                ${employmentGridFrag}
                            }
                        }
                    }
                }
            `,
            { input: input.input },
        );
    }

    public deleteEmployment(input: DeleteEmploymentInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DeleteEmploymentInput!) {
                    employment {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
