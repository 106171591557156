import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AppComponent } from './app.component';
import { AppStoreModule } from './app-store.module';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppTranslateModule } from './app-translate.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { languageInitializer } from './initializers/language.initializer';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { OrganizationInterceptor } from './http-interceptors/organization.interceptor';
import { PrimeNGConfig } from 'primeng/api';
import { NavigatorService } from './services/navigator.service';
import { DonorService } from './services/donor.service';
import { DonationService } from './services/donation.service';
import { CollectionService } from './services/collection.service';
import { ObjectHistoryService } from './services/object-history.service';
import { WarningService } from './services/warning.service';
import { ExpectedDonationService } from './services/expected-donation.service';
import { OrganizationService } from './services/organization.service';
import { UserService } from './services/user.service';
import { NotificationService } from './services/notification.service';
import { AclService } from './services/acl.service';
import { MassEmailService } from './services/mass-email.service';
import { ServiceService } from './services/service.service';
import { AuthenticationModule } from './modules/auth/authentication.module';
import { ConfigComponent } from './modules/layout/components/config/config.component';
import { NotificationsComponent } from './modules/layout/components/notifications/notifications.component';
import { SettingsService } from './services/settings.service';
import { AuthInterceptor } from './modules/databoxes/service/interceptors/auth.interceptor';
import { PersonService } from './services/person.service';
import { WorkplaceService } from './services/workplace.service';
import { PersonAddressService } from './services/person-address.service';
import { NursingCareService } from './services/nursing-care.service';
import { FolderService } from './services/folder.service';
import { ExpertiseGqlService } from './services/expertise.gql-service';
import { VzpGqlService } from './services/vzp.gql-service';
import { FormGqlService } from './services/form.gql-service';
import { FileGqlService } from './services/file.gql-service';
import { ServiceSettingsGqlService } from './services/service-settings.gql-service';
import { BankGqlService } from './services/bank.gql-service';
import { RegionGqlService } from './services/region.gql-service';
import { TerritorialVzpWorkplaceGqlService } from './services/territorial-vzp-workplace.gql-service';
import { EmploymentService } from './services/employment.service';
import { AnamnesisGqlService } from './services/anamnesis.gql-service';
import './utils/observable.utils';
import './utils/form.utils';
import './utils/behavioral-subject.utils';
import { BillingGqlService } from './services/billing.gql-service';
import { InsuranceCompanyGqlService } from './services/insurance-company.gql-service';
import { SchedulerGqlService } from './services/scheduler.gql-service';
import { OpenAiSvc } from './services/open-ai.svc';
import { GlobalGqlService, GQL_TOKEN } from './services/global.gql-service';
import { SchedulerGqlServiceV2 } from './services/scheduler.gql-service-v2';
import { ServiceGqlServiceV2 } from './services/service.gql-service';
import { ServiceSettingsGqlServiceV2 } from './services/service-settings.gql-service-v2';
import { FolderGqlServiceV2 } from './services/folder.gql-service-v2';
import { PersonGqlServiceV2 } from './services/person.gql-service-v2';
import { UserProfileGqlService } from './services/user-profile.gql-service';
import { EmploymentGqlService } from './services/employment.gql-service';
import { UserGqlService } from './services/user.gql-service';

const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
    primeConfig.ripple = true;
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        AuthenticationModule,
        AppStoreModule,
        LayoutModule,
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AppTranslateModule,
        BrowserAnimationsModule,
        ButtonModule,
        TooltipModule,
        BreadcrumbModule,
        StoreRouterConnectingModule.forRoot(),
        AngularSvgIconModule.forRoot(),
        ConfigComponent,
        NotificationsComponent,
    ],
    providers: [
        languageInitializer,
        BreadcrumbService,
        NavigatorService,
        DonorService,
        CollectionService,
        DonationService,
        ObjectHistoryService,
        WarningService,
        ExpectedDonationService,
        MassEmailService,
        OrganizationService,
        UserService,
        NotificationService,
        AclService,
        SettingsService,
        ServiceService,
        PersonService,
        NursingCareService,
        WorkplaceService,
        PersonAddressService,
        FolderService,
        ExpertiseGqlService,
        VzpGqlService,
        FormGqlService,
        FileGqlService,
        EmploymentService,
        ServiceSettingsGqlService,
        BankGqlService,
        RegionGqlService,
        TerritorialVzpWorkplaceGqlService,
        AnamnesisGqlService,
        BillingGqlService,
        InsuranceCompanyGqlService,
        SchedulerGqlService,
        OpenAiSvc,
        GlobalGqlService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OrganizationInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [PrimeNGConfig],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: GQL_TOKEN, useClass: SchedulerGqlServiceV2, multi: true },
        { provide: GQL_TOKEN, useClass: ServiceGqlServiceV2, multi: true },
        { provide: GQL_TOKEN, useClass: ServiceSettingsGqlServiceV2, multi: true },
        { provide: GQL_TOKEN, useClass: FolderGqlServiceV2, multi: true },
        { provide: GQL_TOKEN, useClass: PersonGqlServiceV2, multi: true },
        { provide: GQL_TOKEN, useClass: UserProfileGqlService, multi: true },
        { provide: GQL_TOKEN, useClass: EmploymentGqlService, multi: true },
        { provide: GQL_TOKEN, useClass: UserGqlService, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
