import { Injectable } from '@angular/core';
import {
    OkObject,
    UserGridItemObject, UserObject,
} from '@it2go/types';
import {
    userFrag,
    userGridItemFrag,
} from './types/user.types';
import { ChangePasswordInput } from '@libs/types/src/user/input-type/change-password.input';
import { UserPhotoUpdateInput } from '@libs/types/src/user/input-type/user/user-photo.update.input';
import { UserProfileUpdateInput } from '@libs/types/src/user/input-type/user/user-profile.update-input';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class UserProfileGqlService extends GqlServiceV2 {

    getUserProfile(frag: string = userGridItemFrag) {
        return this.query<UserGridItemObject>(
            `
                query {
                    userProfile {
                        getUserProfile {
                            ${frag}
                        }
                    }
                }
            `,
            {},
        );
    }

    updateUserProfile(input: UserProfileUpdateInput, frag: string = userFrag) {
        return this.mutate<UserObject>(
            `
                mutation($input: UserProfileUpdateInput!) {
                    userProfile {
                        updateProfile(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateUserProfilePhoto(input: UserPhotoUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserPhotoUpdateInput!) {
                    userProfile {
                        updatePhoto(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    changePassword(input: ChangePasswordInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ChangePasswordInput!) {
                    userProfile {
                        changePassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
