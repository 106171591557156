/** !!! Currently, this is required to match database types, so add migration in notification service !!! */
export enum NotificationTypeEnum {
    LIMIT_REACHED = 'limitReached',
    DONATION_CREATED = 'donationCreated',
    DONOR_CREATED = 'donorCreated',
    DONOR_UPDATED = 'donorUpdated',
    EXPECTED_DONATION_NOT_RECEIVED = 'expectedDonationNotReceived',
    DONOR_CUSTOM = 'donorCustom',
    PASSWORD_EXPIRES = 'passwordExpires',
}

export function notificationTitle(type: NotificationTypeEnum | string, title?: string | null): string {
    if (title) return title;

    return Object.entries(NotificationTypeEnum).find(([, value]) => value === type)?.[0] || type;
}

export function notificationSubtitle(type: NotificationTypeEnum | string, subtitle?: string | null): string | null {
    if (subtitle) return subtitle;

    switch (type) {
        default:
            return null;
    }
}

export function notificationLink(type: NotificationTypeEnum | string, entityId?: string | null, link?: string | null): string | null {
    if (link) return link;
    if (!entityId) return null;

    switch (type) {
        case NotificationTypeEnum.DONOR_CREATED:
        case NotificationTypeEnum.DONOR_UPDATED:
        case NotificationTypeEnum.DONOR_CUSTOM:
        case NotificationTypeEnum.EXPECTED_DONATION_NOT_RECEIVED:
            return `crm/donors/${entityId}`;
        case NotificationTypeEnum.DONATION_CREATED:
            return `crm/donations/${entityId}`;
        default:
            return null;
    }
}

export function notificationTypeRule(type: NotificationTypeEnum | string): string | null {
    switch (type) {
        case NotificationTypeEnum.DONOR_CREATED:
        case NotificationTypeEnum.DONOR_UPDATED:
        case NotificationTypeEnum.DONOR_CUSTOM:
        case NotificationTypeEnum.EXPECTED_DONATION_NOT_RECEIVED:
        case NotificationTypeEnum.DONATION_CREATED:
            return 'donor.read';
        default:
            return null;
    }
}
