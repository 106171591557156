import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(
    'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmFJYVF2R2dJfFR0fF9DYkwgOX1dQl9nSH9Rd0VgXXxccXJcR2Q=',
);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
