import { Component, ElementRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { selectAuthState } from '../../../auth/store/auth.selectors';
import { LayoutService } from '../../services/layout.service';
import { AuthState } from '../../../auth/store/auth.state';
import { logoutUser } from '../../../auth/store/auth.actions';
import { selectNotifications } from '../../store/notifications/notifications.selectors';
import { selectUserSettings } from '../../store/layout.selectors';
import { defaultMenuThemes } from '../../../content/service/types/user.types';
import { Router } from '@angular/router';
import { TopbarStore } from './topbar.store';
import { SubscriptionComponent } from '../../../shared/helpers/subscription.component';
import { globalStore } from '../../../shared/shared.global-service';
import { selectCurrentUser } from '../../../../store/global/global.selectors';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    providers: [TopbarStore],
})
export class TopbarComponent extends SubscriptionComponent {
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('profileButton') profileButton!: ElementRef;
    @ViewChild(SidebarComponent) appSidebar!: SidebarComponent;

    name = globalStore.select(selectCurrentUser).pipe(
        map((user) => user?.username || ''),
    );

    darkLogo$ = this.store
        .select(selectUserSettings)
        .pipe(map((settings) => defaultMenuThemes.find((it) => it.name == settings.menuTheme)?.logoColor == 'dark'));

    notificationsCount$ = this.store.select(selectNotifications).pipe(
        map((notifications) => {
            return notifications?.['items'].length;
        }),
    );

    constructor(
        public layoutService: LayoutService,
        private store: Store<AuthState>,
        public translate: TranslateService,
        protected readonly pageStore: TopbarStore,
        private readonly router: Router,
    ) {
        super();
    }

    get logo(): string {
        return this.layoutService.config.menuTheme === 'white' || this.layoutService.config.menuTheme === 'orange'
            ? 'dark'
            : 'white';
    }

    onMenuButtonClick(): void {
        this.layoutService.onMenuToggle();
    }

    onMicrophoneClick(): void {
        return;
    }

    onFullscreenClick(): void {
        return;
    }

    onSearchClick(): void {
        this.layoutService.toggleSearchBar();
    }

    onGuideClick(): void {
        return;
    }

    onProfileClick(): void {
        this.profileButton.nativeElement.click();
        this.router.navigateByUrl('/content/profile');
    }

    onSettingsClick(): void {
        this.layoutService.showConfigSidebar();
    }

    onNotificationClick(): void {
        this.layoutService.toggleNotifications();
    }

    onEmailClick(): void {
        return;
    }

    onMyOfficeClick(): void {
        return;
    }

    onRequestsReportsClick(): void {
        return;
    }

    onReadMessagesClick(): void {
        return;
    }

    logout(): void {
        this.pageStore.api.logout();
        this.store.dispatch(logoutUser());
        this.router.navigateByUrl('/login');
    }
}
