import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    FolderGroupGridObject,
    FolderGroupObject,
} from '@libs/types/src/services/object-type/folder/folder-group.object';
import { filterFrag } from './types/filter.types';
import { folderGroupFrag } from './types/folder.types';
import { FolderGroupCreateInput } from '@libs/types/src/services/input-type/folder/folder-group.create-input';
import { FolderGroupUpdateInput } from '@libs/types/src/services/input-type/folder/folder-group.update-input';
import { OkObject } from '@it2go/types';
import { IdInput } from '@libs/types/src/common/input-type/id.input';

@Injectable()
export class FolderGqlServiceV2 extends GqlServiceV2 {

    public async getFolderGroups(input: GridInput, frag: string = folderGroupFrag) {
        return this.query<FolderGroupGridObject>(
            `
                query($input: GridInput!) {
                    folderGroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async createFolderGroup(input: FolderGroupCreateInput, frag: string = folderGroupFrag) {
        return this.mutate<FolderGroupObject>(
            `
                mutation($input: FolderGroupCreateInput!) {
                    folderGroup {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async updateFolderGroup(input: FolderGroupUpdateInput, frag: string = folderGroupFrag) {
        return this.mutate<FolderGroupObject>(
            `
                mutation($input: FolderGroupUpdateInput!) {
                    folderGroup {
                        update(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public async deleteFolderGroup(input: IdInput, frag: string = 'ok') {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    folderGroup {
                        delete(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

}
