import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchAllLast',
})
export class SearchAllLastPipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        return Object.values(value).find((it) => it === false) !== null;
    }

}
