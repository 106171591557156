import { Injectable } from '@angular/core';
import { ComponentStoreFactory } from '../../../shared/store/common/component-store.factory';
import { Store } from '@ngrx/store';
import { DonorGridObject } from '@it2go/types';
import { DonorService } from '../../../../services/donor.service';
import { map } from 'rxjs/operators';
import { DonationService } from '../../../../services/donation.service';
import { FoundItem } from './search.types';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import { GridInput } from '@it2go/data-grid';
import { CollectionService } from '../../../../services/collection.service';
import { ObjectHistoryService } from '../../../../services/object-history.service';
import {
    HistoryItemGridItemObject,
    HistoryItemGridObject,
} from '@libs/types/src/object-history/object-type/history-item.object';
import { parseAuditLogHistory } from '../../../shared/components/audit-log-tree/audit-log-tree.parser';
import { combineLatest, Observable, Subject } from 'rxjs';
import { FolderService } from '../../../../services/folder.service';
import { ServiceService } from '../../../../services/service.service';

interface State {
    results: FoundItem[],
    historyResults: FoundItem[],
}

const defaultState: State = {
    results: [],
    historyResults: [],
};

@Injectable()
export class SearchStore extends ComponentStoreFactory<State> {

    public searchTabs: string[] = [];
    public searchTabsSubj = new Subject<string[]>();

    public allowedTabs: Record<string, string[]> = {
        'donation.read': ['donor', 'donation', 'collection'],
        'folder.read': ['folder', 'performedProcedure'],
    };

    public currentPage: Record<string, number> = {};
    public currHistoryPage = 0;
    public isLast: Record<string, boolean> = {};
    public isHistoryLast = false;

    public dateOverride: Record<string, string> = {
        donation: 'receivedAt',
        performedProcedure: 'dateFrom',
    };

    public labelOverride: Record<string, string> = {
        performedProcedure: 'procedures.performed.labelMulti',
    };

    public loading = 0;

    public only$: Record<string, Observable<FoundItem[]>> = {};
    public onlyHistory$ = this.data.historyResults$;

    constructor(
        svc: DonorService,
        donationSvc: DonationService,
        collectionSvc: CollectionService,
        historySvc: ObjectHistoryService,
        folderSvc: FolderService,
        serviceSvc: ServiceService,
        store: Store,
    ) {
        super(defaultState, store, svc, donationSvc, collectionSvc, historySvc, folderSvc, serviceSvc);
        this.init();

        this.subs.push(
            combineLatest(Object.keys(this.allowedTabs).map((rule) => this.isAllowed[rule])).subscribe((allowed) => {
                this.searchTabs = [];
                Object.values(this.allowedTabs).forEach((tabs, index) => {
                    if (allowed[index]) {
                        this.searchTabs = [...this.searchTabs, ...tabs];
                    }
                });
                this.init();
            }),
        );
    }

    public init(): void {
        this.clear();
        this.searchTabsSubj.next(this.searchTabs);
        this.searchTabs.forEach((tab) => {
            this.only$[tab] = this.data.results$.pipe(
                map((it) => it.filter((i) => i.entity === tab)),
            );
        });

        this.subs.push(
            ...this.searchTabs.map((tab) => {
                const method = this.getMethod(tab);
                return (this.apiSuccess as any)[method].subscribe(async (it: DonorGridObject) => {
                    this.loading--;
                    this.isLast[tab] = it['paging'].page === it['paging'].lastPage;
                    await this.addResults(
                        this.currentPage[tab] <= 1,
                        (it['items'] || []).map((item: any) => {
                            const foundItem = <FoundItem>{
                                entity: tab,
                                data: item,
                                date: item[this.dateOverride[tab] || 'createdAt'],
                            };
                            switch (tab) {
                                case 'performedProcedure':
                                    foundItem.date = item.work.dateFrom;
                                    break;
                            }

                            return foundItem;
                        }),
                    );
                });
            }),
            this.apiSuccess.getHistory.subscribe(async (it: HistoryItemGridObject) => {
                this.loading--;
                this.isHistoryLast = it['paging'].page === it['paging'].lastPage;
                const { parsed } = parseAuditLogHistory(it['items'] || [], false);
                this.patchState({
                    historyResults: [
                        ...await this.get(this.data.historyResults$),
                        ...(it['items'] || []).map((history: HistoryItemGridItemObject) => (<FoundItem>{
                            entity: 'history',
                            data: history,
                            date: history.timestamp,
                            tree: parsed[history.id],
                        })),
                    ],
                });
            }),
        );
    }

    public nextPage(entity: string, _search: string, lazyLoaded: boolean = false): void {
        const search = _search.replaceAll('@', ' ').replaceAll('.', ' ');
        this.searchTabs.forEach((tab) => {
            if (!this.isLast[tab] && [tab, 'all'].includes(entity)) {
                if (lazyLoaded && this.currentPage[tab] > 0) return;
                this.loading++;
                (this.api as any)[this.getMethod(tab)](this.filterInput(++this.currentPage[tab], search, this.dateOverride[tab] || 'createdAt'));
            }
        });

        if (!this.isHistoryLast && ['history'].includes(entity)) {
            if (lazyLoaded && this.currHistoryPage > 0) return;
            this.loading++;
            this.api.getHistory(
                (new FilterBuilder())
                    .limit(50)
                    .page(++this.currHistoryPage)
                    .search(search)
                    .sort('timestamp', 'DESC')
                    .where('changedFields', '{}', 'NEQ')
                    .where('entityName', this.searchTabs, 'IN')
                    .filter(),
            );
        }
    }

    public clear() {
        this.loading = 0;
        this.searchTabs.forEach((tab) => {
            this.currentPage[tab] = 0;
            this.isLast[tab] = false;
        });
        this.patchState({ results: [], historyResults: [] });
    }

    private filterInput(page: number, search: string, orderColumn: string = 'createdAt'): GridInput {
        return FilterBuilder.filter({
            search: search,
            sort: { column: orderColumn, direction: 'DESC' },
            page,
            limit: 50,
        });
    }

    private async addResults(merge: boolean, results: FoundItem[]): Promise<void> {
        if (merge) {
            this.patchState({
                results: [
                    ...await this.get(this.data.results$),
                    ...results,
                ].sort((a, b) => a.date < b.date ? 1 : -1),
            });
        } else {
            this.patchState({
                results: [
                    ...await this.get(this.data.results$),
                    ...results,
                ],
            });
        }
    }

    private getMethod(tab: string): string {
        return `getSearch${tab.substring(0, 1).toUpperCase()}${tab.substring(1)}`;
    }

}
