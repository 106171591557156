import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const translationFiles = [
    '',
    'acl',
    'anamnesis',
    'billing',
    'collection',
    'common',
    'donation',
    'donationReport',
    'donor',
    'enum',
    'emailTemplates',
    'errors',
    'folder',
    'folderContact',
    'form',
    'history',
    'organization',
    'organization-settings',
    'person',
    'procedures',
    'service',
    'scheduler',
    'summaryEmails',
    'massEmails',
    'table',
    'user',
    'warning',
    'workplace',
    'files',
    'workData',
    'workPlan',
    'validators',
    'voucher',
    'vzp',
];

function mergeObjectsRecursively(
    object: Record<string, unknown>,
    mergedObject: Record<string, unknown>,
): Record<string, unknown> {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            if (typeof object[key] === 'object' && object[key] !== null) {
                mergedObject[key] = mergeObjectsRecursively(
                    mergedObject[key] as Record<string, unknown>,
                    object[key] as Record<string, unknown>,
                );
            } else {
                mergedObject[key] = object[key];
            }
        }
    }

    return mergedObject;
}

class It2GoTranslateLoader extends TranslateLoader {
    constructor(private readonly http: HttpClient) {
        super();
    }

    getTranslation(lang: string): Observable<any> {
        return forkJoin(
            translationFiles.map((key) => {
                if (key == '') {
                    return this.http.get(`assets/i18n/${lang}.json`);
                } else {
                    return this.http.get(`assets/i18n/${lang}/${key}.json`);
                }
            }),
        ).pipe(
            map((responses: any) => {
                let mergedObject: Record<string, unknown> = {};
                let i = 0;

                for (const obj of responses) {
                    const rootKey = translationFiles[i++];
                    let o = obj as Record<string, unknown>;
                    if (rootKey != '') o = { [rootKey]: obj };

                    mergedObject = mergeObjectsRecursively(o, mergedObject);
                }

                return mergedObject;
            }),
        );
    }
}

function httpLoaderFactory(http: HttpClient): It2GoTranslateLoader {
    return new It2GoTranslateLoader(http);
}

@NgModule({
    imports: [
        TranslateModule.forRoot({
            defaultLanguage: 'cz',
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [TranslateModule],
})
export class AppTranslateModule {}
