import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

declare module 'rxjs' {
    interface Observable<T> {
        firstGridItem<K>(): Observable<K>;

        gridItems<K>(filterActive?: boolean): Observable<K[]>;

        patchForm(form: FormGroup): Subscription;

        /**
         * @Deprecated("use store.data.xxx$")
         */
        promise(): Promise<T>;

        callAfter(callback: CallableFunction): Promise<T>;

        /**
         * Returns an array of single column values
         * [{ a: 1 }, { a: 2, b: 3}].column() => [1, 2]
         */
        column<K = string>(column: string): Observable<K[]>;
    }
}

Object.defineProperty(Observable.prototype, 'firstGridItem', {
    value: function firstGridItem<T>(): Observable<T> {
        return this.pipe(map((it: any) => it?.['items']?.[0] || null));
    },
    writable: true,
    configurable: true,
});

Object.defineProperty(Observable.prototype, 'gridItems', {
    value: function firstGridItem<T>(
        filterActive: boolean = false,
    ): Observable<T> {
        return this.pipe(
            map((it: any) => {
                const items = it?.['items'] || [];
                if (filterActive) {
                    return items.filter((it: any) => it.active !== false);
                }

                return items;
            }),
        );
    },
    writable: true,
    configurable: true,
});

Object.defineProperty(Observable.prototype, 'patchForm', {
    value: function patchForm(form: FormGroup): Subscription {
        return this.subscribe((it: any) => {
            form.patchValue(it);
        });
    },
    writable: true,
    configurable: true,
});

/**
 * @Deprecated("use store.data.xxx$")
 */
Object.defineProperty(Observable.prototype, 'promise', {
    value: function promise<T>(): Promise<T> {
        if (this instanceof BehaviorSubject) {
            return this.currentValue();
        }

        return firstValueFrom(this);
    },
    writable: true,
    configurable: true,
});

Object.defineProperty(Observable.prototype, 'callAfter', {
    value: async function callAfter(callback: CallableFunction): Promise<void> {
        await callback(await this.promise());
    },
    writable: true,
    configurable: true,
});

Object.defineProperty(Observable.prototype, 'column', {
    value: function column<T>(column: string): Observable<T[]> {
        return this.pipe(
            map((it: any[]) => it.map((ij) => ij[column] ?? null)),
        );
    },
    writable: true,
    configurable: true,
});
