import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { camelize } from '@angular-devkit/core/src/utils/strings';

@Pipe({
    name: 'formatNotificationHeader',
    standalone: true,
})
export class FormatNotificationHeaderPipe implements PipeTransform {

    public transform(group: any, translate: TranslateService): any {
        const link = group.items[0];
        const data = JSON.parse(link.data || '{}');

        const parts = link.url!
            .split('/')
            .slice(0, group.match + 2)
            .filter((it: any) => it);

        return parts.map((it: any, index: any) => {
            const value = data[index] || it;
            if (['__persons'].includes(value) && index < (parts.length - 1)) return '';

            if (value.startsWith('__')) {
                return translate.instant(`breadcrumbs.${value.slice(2)}`);
            } else {
                return value;
            }
        })
            .slice(1)
            .join(' ');
    }

}

@Pipe({
    name: 'formatNotificationLink',
    standalone: true,
})
export class FormatNotificationLinkPipe implements PipeTransform {

    public transform(match: any, link: any, translate: TranslateService): any {
        const data = JSON.parse(link.data || '{}');

        const parts = link.url!
            .split('/')
            .filter((it: any) => it);
        let sliced = Math.max((match + 1) || 1, parts.length - 1);
        const works = parts[1] == 'works'; // TODO hotfix, chtělo by to předělat - v global.effects je také hotfix pro __works
        if (works) {
            sliced = parts.length - 1;
        }

        return parts.map((it: any, index: any) => {
            const value = data[index + (works ? 1 : 0)] || it;
            if (['__folders'].includes(value) && index < (parts.length - 1)) return '';

            if (value.startsWith('__')) {
                return translate.instant(`breadcrumbs.${camelize(value.slice(2))}`);
            } else {
                const tmp = `breadcrumbs.${camelize(value)}`;
                const trs = translate.instant(tmp);
                return tmp == trs ? value : trs;
            }
        })
            .slice(sliced)
            .filter((it: any) => it);
    }

}
