import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getRules } from '../../store/global/global.actions';
import { FilterBuilder } from '../content/helper/filter.builder';
import { getUserTables } from '../shared/store/shared.actions';
import { TopbarComponent } from './components/topbar/topbar.component';
import { MenuService } from './services/menu.service';
import { LayoutService } from './services/layout.service';
import { LayoutState } from './store/layout.state';
import { getUserSettings, setBackendUrl } from './store/layout.actions';
import { SubscriptionComponent } from '../shared/helpers/subscription.component';
import { selectCurrentOrganizationId } from '../../store/global/global.selectors';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
})
export class LayoutComponent
    extends SubscriptionComponent
    implements OnDestroy, OnInit, OnChanges
{
    @ViewChild(TopbarComponent) appTopbar!: TopbarComponent;
    @Input() backendUrl = '';

    overlayMenuOpenSubscription: Subscription;
    menuOutsideClickListener: (() => void) | null = null;

    constructor(
        private menuService: MenuService,
        public layoutService: LayoutService,
        public renderer: Renderer2,
        public router: Router,
        private store: Store<LayoutState>,
        private globalStore: Store,
    ) {
        super();

        this.overlayMenuOpenSubscription =
            this.layoutService.overlayOpen$.subscribe(() => {
                if (!this.menuOutsideClickListener) {
                    this.menuOutsideClickListener = this.renderer.listen(
                        'document',
                        'click',
                        (event) => {
                            const isOutsideClicked = !(
                                this.appTopbar?.appSidebar.el.nativeElement.isSameNode(
                                    event.target,
                                ) ||
                                this.appTopbar?.appSidebar.el.nativeElement.contains(
                                    event.target,
                                ) ||
                                this.appTopbar?.menuButton.nativeElement.isSameNode(
                                    event.target,
                                ) ||
                                this.appTopbar?.menuButton.nativeElement.contains(
                                    event.target,
                                )
                            );
                            if (isOutsideClicked) {
                                this.hideMenu();
                            }
                        },
                    );
                }

                if (this.layoutService.state.staticMenuMobileActive) {
                    this.blockBodyScroll();
                }
            });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.hideMenu();
                this.unblockBodyScroll();
            });
    }

    get containerClass(): Record<string, boolean> {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'layout-overlay': this.layoutService.config.menuType === 'overlay',
            'layout-static':
                this.layoutService.config.menuType === 'static' &&
                this.layoutService.submenuContent === null,
            'layout-slim':
                this.layoutService.config.menuType === 'slim' &&
                this.layoutService.submenuContent === null,
            'layout-horizontal':
                this.layoutService.config.menuType === 'horizontal',
            'layout-compact':
                this.layoutService.config.menuType === 'compact' ||
                (!['overlay', 'horizontal'].includes(
                    this.layoutService.config.menuType,
                ) &&
                    this.layoutService.submenuContent !== null),
            'layout-sidebar-dim':
                this.layoutService.config.colorScheme === 'dim',
            'layout-sidebar-dark':
                this.layoutService.config.colorScheme === 'dark',
            'layout-overlay-active':
                this.layoutService.state.overlayMenuActive ||
                this.layoutService.state.staticMenuMobileActive,
            'layout-mobile-active':
                this.layoutService.state.staticMenuMobileActive,
            'layout-static-inactive':
                this.layoutService.state.staticMenuDesktopInactive &&
                this.layoutService.config.menuType === 'static',
            'p-ripple-disabled': !this.layoutService.config.ripple,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    get sidebarClass(): string {
        return this.layoutService.config.colorScheme === 'light'
            ? `layout-sidebar-${this.layoutService.config.menuTheme}`
            : '';
    }

    ngOnInit(): void {
        this.store.dispatch(getUserSettings());
        this.store.dispatch(getRules());
        this.subs.push(
            this.globalStore
                .select(selectCurrentOrganizationId)
                .subscribe((it) => this.store.dispatch(getRules())),
        );
        this.store.dispatch(getUserTables(FilterBuilder.singlePageInput()));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.store.dispatch(
            setBackendUrl({
                backendUrl: changes['backendUrl'].currentValue,
            }),
        );
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(
                new RegExp(
                    `(^|\\b)${'blocked-scroll'.split(' ').join('|')}(\\b|$)`,
                    'gi',
                ),
                ' ',
            );
        }
    }

    hideMenu(): void {
        this.layoutService.state.overlayMenuActive = false;
        this.layoutService.state.staticMenuMobileActive = false;
        this.layoutService.state.menuHoverActive = false;
        this.menuService.reset();
        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
            this.menuOutsideClickListener = null;
        }
        this.unblockBodyScroll();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
    }
}
