import { WorkExportInput } from './../../../../types/src/services/input-type/work/work.export-input';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    CreateWorkInput,
    DeletePerformedProcedureInput,
    DeleteWorkInput,
    GetServiceInput,
    MedicalProcedureGridObject,
    OkObject,
    PatchServiceInput,
    PerformedProcedureGridObject,
    ServiceBasicInfoCreateInput,
    ServiceBasicInfoDeleteInput,
    ServiceBasicInfoGridObject,
    ServiceBasicInfoUpdateInput,
    ServiceCreateInput,
    ServiceGridObject,
    ServiceObject,
    ServicePaymentCreateInput,
    ServicePaymentDeleteInput,
    ServicePaymentGetInput,
    ServicePaymentGridObject,
    ServicePaymentMedicalProcedureCreateInput,
    ServicePaymentMedicalProcedureDeleteInput,
    ServicePaymentMedicalProcedureGridObject,
    ServicePaymentMedicalProcedureUpdateInput,
    ServicePaymentObject,
    ServicePaymentUpdateInput,
    ServiceTypeGridObject,
    UpdatePerformedProcedureInput,
    UpdateWorkInput,
    WorkGridObject,
    WorkObject,
} from '@it2go/types';
import { filterFrag } from './types/filter.types';
import {
    doctorSpecializationFrag,
    medicalProcedureFrag,
    serviceBasicInfoGridItemFrag,
    serviceFrag,
    serviceGridDetailFrag,
    serviceGridItemFrag,
    serviceMedicalProcedureGridItemFrag,
    serviceNursingCareDiagnosisCodeGridItemFrag,
    serviceNursingCareDiagnosisGroupGridItemFrag,
    serviceOperationalReportGridItemFrag,
    servicePatternObjectFrag,
    servicePaymentFrag,
    servicePaymentGridItemFrag,
    servicePaymentMedicalProcedureGridItemFrag,
    serviceTypeGridItemFrag,
} from './types/service.types';
import {
    performedProcedureGridItemFrag,
    performedProcedureGridItemSearchFrag,
    workFrag,
    workGridItemFrag,
} from './types/work.types';
import { FolderPrintObject } from '@libs/types/src/services/object-type/folder/folder-print-object';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import {
    ServiceMedicalProcedureUpdateInput,
} from '@libs/types/src/services/input-type/services/service-medical-procedure.update-input';
import {
    ServiceMedicalProcedureCreateInput,
} from '@libs/types/src/services/input-type/services/service-medical-procedure.create-input';
import {
    ServicePatternDocumentCreateInput,
    ServicePatternStructureCreateInput,
} from '@libs/types/src/services/input-type/services/service-pattern.create-input';
import { ServicePatternObject } from '@libs/types/src/services/object-type/service/service-pattern.object';
import { ServicePatternDeleteInput } from '@libs/types/src/services/input-type/services/service-pattern-delete.input';
import {
    ServicePatternDocumentUpdateInput,
    ServicePatternStructureUpdateInput,
} from '@libs/types/src/services/input-type/services/service-pattern.update-input';
import { ProcessPatternInput } from '@libs/types/src/services/input-type/services/process-pattern.input';
import { DoctorSpecializationGridObject } from '@libs/types/src/services/object-type/vzp/doctor-specialization.object';
import {
    ServiceOperationalReportCreateInput,
} from '@libs/types/src/services/input-type/services/service-operational-report.create-input';
import {
    ServiceOperationalReportGridObject,
} from '@libs/types/src/services/object-type/service/service-operational-report.object';
import {
    ServiceOperationalReportUpdateInput,
} from '@libs/types/src/services/input-type/services/service-operational-report.update-input';
import { voucherPrescribedMedicalProcedureFrag } from './types/voucher.types';
import {
    ServiceNursingCareDiagnosisCodeGridObject,
} from '@libs/types/src/services/object-type/service/service-nursing-care-diagnosis-code.object';
import {
    ServiceNursingCareDiagnosisCodeCreateInput,
} from '@libs/types/src/services/input-type/services/service-nursing-care-diagnosis-code.create-input';
import {
    ServiceNursingCareDiagnosisCodeUpdateInput,
} from '@libs/types/src/services/input-type/services/service-nursing-care-diagnosis-code.update-input';
import {
    ServiceNursingCareDiagnosisGroupGridObject,
} from '@libs/types/src/services/object-type/service/service-nursing-care-diagnosis-group.object';
import {
    ServiceNursingCareDiagnosisGroupCreateInput,
} from '@libs/types/src/services/input-type/services/service-nursing-care-diagnosis-groups.create-input';
import {
    ServiceNursingCareDiagnosisGroupUpdateInput,
} from '@libs/types/src/services/input-type/services/service-nursing-care-diagnosis-groups.update-input';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class ServiceGqlServiceV2 extends GqlServiceV2 {

    public getServices(input: GridInput | null = null, frag: string = serviceGridItemFrag) {
        return this.query<ServiceGridObject>(
            `
                query($input: GridInput) {
                    service {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceDetail(input: GridInput | null, frag: string = serviceGridDetailFrag) {
        return this.query<ServiceGridObject>(
            `
                query($input: GridInput) {
                    service {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    /**
     * @Deprecated()
     */
    public getService(input: GetServiceInput, frag: string = serviceFrag) {
        return this.query<ServiceObject>(`
                query ($input: GetServiceInput!) {
                    service {
                        get(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createService(input: ServiceCreateInput, frag: string = serviceFrag) {
        return this.mutate<ServiceObject>(
            `
                mutation($input: ServiceCreateInput!) {
                    service {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public patchService(input: PatchServiceInput, frag: string = serviceFrag) {
        return this.mutate<ServiceObject>(
            `
                mutation($input: PatchServiceInput!) {
                    service {
                        patch(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceTypes(input: GridInput | null = null, frag: string = serviceTypeGridItemFrag) {
        return this.query<ServiceTypeGridObject>(
            `
                query($input: GridInput) {
                    serviceType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getMedicalProcedures(input: GridInput | null, frag: string = medicalProcedureFrag) {
        return this.query<MedicalProcedureGridObject>(
            `
                query($input: GridInput) {
                    medicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getWorks(input: GridInput | null, frag: string = workGridItemFrag) {
        return this.query<WorkGridObject>(
            `
                query($input: GridInput) {
                    work {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteWork(input: DeleteWorkInput) {
        return this.mutate(
            `
                mutation($input: DeleteWorkInput!) {
                    work {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createWork(input: CreateWorkInput, frag: string = workFrag) {
        return this.mutate<WorkObject>(
            `
                mutation($input: CreateWorkInput!) {
                    work {
                        create(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWork(input: UpdateWorkInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdateWorkInput!) {
                    work {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getPerformedProcedures(input: GridInput | null, frag: string = performedProcedureGridItemFrag) {
        return this.query<PerformedProcedureGridObject>(
            `
                query($input: GridInput) {
                    performedProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    /**
     * Used in globalSearch
     * @param input
     */
    public getSearchPerformedProcedure(input: GridInput | null, frag: string = performedProcedureGridItemSearchFrag) {
        return this.query<PerformedProcedureGridObject>(
            `
                query($input: GridInput) {
                    performedProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createPerformedProcedure(input: UpdatePerformedProcedureInput) {
        return this.mutate(
            `
            mutation($input: UpdatePerformedProcedureInput!) {
                performedProcedure {
                    create(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public createPerformedProcedures(input: UpdatePerformedProcedureInput[]) {
        return this.mutate(
            `
            mutation($input: [UpdatePerformedProcedureInput!]!) {
                performedProcedure {
                    createMany(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public updatePerformedProcedure(input: UpdatePerformedProcedureInput) {
        return this.mutate(
            `
            mutation($input: UpdatePerformedProcedureInput!) {
                performedProcedure {
                    update(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public deletePerformedProcedure(input: DeletePerformedProcedureInput) {
        return this.mutate(
            `
            mutation($input: DeletePerformedProcedureInput!) {
                performedProcedure {
                    delete(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    exportWorks(input: WorkExportInput): Promise<FolderPrintObject> {
        return this.query(
            `
                query($input: WorkExportInput!) {
                    work {
                        export(input: $input) {
                            fileName
                            pdfBase64
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceBasicInfos(input: GridInput | null, frag: string = serviceBasicInfoGridItemFrag): Promise<ServiceBasicInfoGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceBasicInfo {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceBasicInfo(input: ServiceBasicInfoCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServiceBasicInfoCreateInput!) {
                    serviceBasicInfo {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceBasicInfo(input: ServiceBasicInfoUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServiceBasicInfoUpdateInput!) {
                    serviceBasicInfo {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceBasicInfo(input: ServiceBasicInfoDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServiceBasicInfoDeleteInput!) {
                    serviceBasicInfo {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceMedicalProcedures(input: GridInput, frag: string = serviceMedicalProcedureGridItemFrag) {
        return this.query(
            `
                query($input: GridInput) {
                    serviceMedicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getVoucherPrescribedMedicalProcedures(input: GridInput, frag: string = voucherPrescribedMedicalProcedureFrag) {
        return this.query(
            `
                query($input: GridInput) {
                    voucherPrescribedMedicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceMedicalProcedure(input: ServiceMedicalProcedureCreateInput) {
        return this.mutate(
            `
                mutation($input: ServiceMedicalProcedureCreateInput!) {
                    serviceMedicalProcedure {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceMedicalProcedure(input: ServiceMedicalProcedureUpdateInput) {
        return this.mutate(
            `
                mutation($input: ServiceMedicalProcedureUpdateInput!) {
                    serviceMedicalProcedure {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceMedicalProcedure(input: IdInput) {
        return this.mutate(
            `
                mutation($input: IdInput!) {
                    serviceMedicalProcedure {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServicePayment(input: ServicePaymentGetInput, frag: string = servicePaymentFrag): Promise<ServicePaymentObject> {
        return this.query(
            `
                query($input: ServicePaymentGetInput!) {
                    servicePayment {
                        get(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServicePayments(input: GridInput | null, frag: string = servicePaymentGridItemFrag): Promise<ServicePaymentGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    servicePayment {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServicePayment(input: ServicePaymentCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePaymentCreateInput!) {
                    servicePayment {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServicePayment(input: ServicePaymentUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePaymentUpdateInput!) {
                    servicePayment {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServicePayment(input: ServicePaymentDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePaymentDeleteInput!) {
                    servicePayment {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServicePaymentMedicalProcedures(input: GridInput | null, frag: string = servicePaymentMedicalProcedureGridItemFrag): Promise<ServicePaymentMedicalProcedureGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    servicePaymentMedicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServicePaymentMedicalProcedure(input: ServicePaymentMedicalProcedureCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePaymentMedicalProcedureCreateInput!) {
                    servicePaymentMedicalProcedure {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServicePaymentMedicalProcedure(input: ServicePaymentMedicalProcedureUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePaymentMedicalProcedureUpdateInput!) {
                    servicePaymentMedicalProcedure {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServicePaymentMedicalProcedure(input: ServicePaymentMedicalProcedureDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePaymentMedicalProcedureDeleteInput!) {
                    servicePaymentMedicalProcedure {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServicePatternStructure(input: ServicePatternStructureCreateInput, frag: string = servicePatternObjectFrag) {
        return this.mutate<ServicePatternObject>(
            `
                mutation($input: ServicePatternStructureCreateInput!) {
                    servicePattern {
                        createStructure(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServicePatternDocument(input: ServicePatternDocumentCreateInput, frag: string = servicePatternObjectFrag) {
        return this.mutate<ServicePatternObject>(
            `
                mutation($input: ServicePatternDocumentCreateInput!) {
                    servicePattern {
                        createDocument(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServicePatternStructure(input: ServicePatternStructureUpdateInput, frag: string = servicePatternObjectFrag) {
        return this.mutate<ServicePatternObject>(
            `
                mutation($input: ServicePatternStructureUpdateInput!) {
                    servicePattern {
                        updateStructure(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServicePatternDocument(input: ServicePatternDocumentUpdateInput, frag: string = servicePatternObjectFrag) {
        return this.mutate<ServicePatternObject>(
            `
                mutation($input: ServicePatternDocumentUpdateInput!) {
                    servicePattern {
                        updateDocument(input: $input) {
                            ${frag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServicePatterns(input: GridInput, frag: string = servicePatternObjectFrag) {
        return this.query(
            `
                query($input: GridInput) {
                    servicePattern {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServicePattern(input: ServicePatternDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServicePatternDeleteInput!) {
                    servicePattern {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getProcessedPattern(input: ProcessPatternInput) {
        return this.query(
            `
                query($input: ProcessPatternInput!) {
                    servicePattern {
                        processPattern(input: $input) {
                            content
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getDoctorSpecializations(input: GridInput | null = null, frag: string = doctorSpecializationFrag) {
        return this.query<DoctorSpecializationGridObject>(
            `
                query($input: GridInput) {
                    doctorSpecialization {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceOperationalReports(input: GridInput | null, frag: string = serviceOperationalReportGridItemFrag): Promise<ServiceOperationalReportGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceOperationalReport {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceOperationalReport(input: ServiceOperationalReportCreateInput) {
        return this.mutate<OkObject>(
            `
            mutation($input: ServiceOperationalReportCreateInput!) {
                serviceOperationalReport {
                    create(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public updateServiceOperationalReport(input: ServiceOperationalReportUpdateInput) {
        return this.mutate<OkObject>(
            `
            mutation($input: ServiceOperationalReportUpdateInput!) {
                serviceOperationalReport {
                    update(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public deleteServiceOperationalReport(input: IdInput) {
        return this.mutate<OkObject>(
            `
            mutation($input: IdInput!) {
                serviceOperationalReport {
                    delete(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    public getServiceNursingCareDiagnosisCodes(input: GridInput | null, frag: string = serviceNursingCareDiagnosisCodeGridItemFrag): Promise<ServiceNursingCareDiagnosisCodeGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceNursingCareDiagnosisCode {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceNursingCareDiagnosisCode(input: ServiceNursingCareDiagnosisCodeCreateInput) {
        return this.mutate<OkObject>(
            `
            mutation($input: ServiceNursingCareDiagnosisCodeCreateInput!) {
                serviceNursingCareDiagnosisCode {
                    create(input: $input) {
                        ok
                    }
                }
            }
            `,
            { input },
        );
    }

    public updateServiceNursingCareDiagnosisCode(input: ServiceNursingCareDiagnosisCodeUpdateInput) {
        return this.mutate<OkObject>(
            `
            mutation($input: ServiceNursingCareDiagnosisCodeUpdateInput!) {
                serviceNursingCareDiagnosisCode {
                    update(input: $input) {
                        ok
                    }
                }
            }
            `,
            { input },
        );
    }

    public deleteServiceNursingCareDiagnosisCode(input: IdInput) {
        return this.mutate<OkObject>(
            `
            mutation($input: IdInput!) {
                serviceNursingCareDiagnosisCode {
                    delete(input: $input) {
                        ok
                    }
                }
            }
            `,
            { input },
        );
    }

    public getServiceNursingCareDiagnosisGroups(input: GridInput | null, frag: string = serviceNursingCareDiagnosisGroupGridItemFrag): Promise<ServiceNursingCareDiagnosisGroupGridObject> {
        return this.query(
            `
                query($input: GridInput) {
                    serviceNursingCareDiagnosisGroup {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${frag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createServiceNursingCareDiagnosisGroup(input: ServiceNursingCareDiagnosisGroupCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServiceNursingCareDiagnosisGroupCreateInput!) {
                    serviceNursingCareDiagnosisGroup {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateServiceNursingCareDiagnosisGroup(input: ServiceNursingCareDiagnosisGroupUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ServiceNursingCareDiagnosisGroupUpdateInput!) {
                    serviceNursingCareDiagnosisGroup {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteServiceNursingCareDiagnosisGroup(input: IdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    serviceNursingCareDiagnosisGroup {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
