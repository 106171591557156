import { UpsertUserWysiwygAutocompleteInput } from './../../../../types/src/user/input-type/upsert-user-wysiwyg-autocomplete.input';
import { UserWysiwygAutocompleteObject } from './../../../../types/src/user/object-type/user-wysiwyg-autocomplete.object';
import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    CreateUserInput,
    OkObject,
    UpsertUserTableInput,
    UserGridObject,
    UserIdInput,
    UserObject,
    UserRoleGridObject,
    UserTableGridObject,
    UserTableObject,
} from '@it2go/types';
import { userTableFrag, userTableGridItemFrag } from './types/user-table.types';
import { filterFrag } from './types/filter.types';
import {
    userExperienceItemFrag,
    userFrag,
    userGridItemFrag,
    userRoleGridItemFrag,
    userWorkplaceFrag,
} from './types/user.types';
import { UserUpdateInput } from '@libs/types/src/user/input-type/user/user-update.input';
import { gql } from '@apollo/client/core';
import { map } from 'rxjs';
import { userWysiwygAutocompleteFrag } from './types/user-wysiwyg.types';
import { UserWorkplaceCreateInput } from '@libs/types/src/user/input-type/workplace/user-workplace.create-input';
import { UserWorkplaceUpdateInput } from '@libs/types/src/user/input-type/workplace/user-workplace.update-input';
import { IdInput } from '@libs/types/src/common/input-type/id.input';
import { SetUserPasswordInput } from '@libs/types/src/user/input-type/set-user-password.input';
import { UserPhotoUpdateInput } from '@libs/types/src/user/input-type/user/user-photo.update.input';
import { UserExperienceUpdateInput } from '@libs/types/src/user/input-type/user-experience.update-input';
import { UserExperienceCreateInput } from '@libs/types/src/user/input-type/user-experience.create-input';
import { ProfileIdInput } from '@libs/types/src/user/input-type/profile-id.input';
import { GqlServiceV2 } from '../modules/content/service/gql.service-v2';

@Injectable()
export class UserGqlService extends GqlServiceV2 {

    getUsers(input: GridInput | null) {
        return this.query<UserGridObject>(
            `
                query($input: GridInput) {
                    user {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateUser(input: UserUpdateInput) {
        return this.mutate<UserGridObject>(
            `
                mutation($input: UserUpdateInput!) {
                    user {
                        update(input: $input) {
                            ${userFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateUserPhoto(input: UserPhotoUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserPhotoUpdateInput!) {
                    user {
                        updatePhoto(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getUserTables(input: GridInput | null) {
        return this.query<UserTableGridObject>(
            `
                query($input: GridInput) {
                    userTable {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userTableGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createUser(input: CreateUserInput) {
        return this.apollo.mutate<{ user: { create: UserObject } }>({
            mutation: gql`
                mutation($input: CreateUserInput!) {
                    user {
                        create(input: $input) {
                            id
                            email
                            name
                            surname
                            titleBeforeName
                            titleAfterName
                        }
                    }
                }
            `,
            variables: { input },
        })
            .pipe(map((result) => result.data!.user.create));
    }

    upsertUserTable(input: UpsertUserTableInput) {
        return this.mutate<UserTableObject>(
            `
                mutation($input: UpsertUserTableInput!) {
                    userTable {
                        upsert(input: $input) {
                            ${userTableFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getUserRoles(input: GridInput | null = null) {
        return this.query<UserRoleGridObject>(
            `
                query($input: GridInput) {
                    userRole {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userRoleGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public generateUserPassword(input: UserIdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserIdInput!) {
                    user {
                        generatePassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public setUserPassword(input: SetUserPasswordInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: SetUserPasswordInput!) {
                    user {
                        setUserPassword(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getUserWysiwygAutocompletions() {
        return this.query<UserWysiwygAutocompleteObject>(
            `
                query {
                    userWysiwyg {
                        getAutocomplete {
                            items {
                                ${userWysiwygAutocompleteFrag}
                            }
                        }
                    }
                }
            `,
            {},
        );
    }

    public upsertUserWysiwygAutocompletion(input: UpsertUserWysiwygAutocompleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpsertUserWysiwygAutocompleteInput!) {
                    userWysiwyg {
                        upsertAutocomplete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createUserWorkplace(input: UserWorkplaceCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserWorkplaceCreateInput!) {
                    userWorkplace {
                        create(input: $input) {
                            id
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateUserWorkplace(input: UserWorkplaceUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UserWorkplaceUpdateInput!) {
                    userWorkplace {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteUserWorkplace(input: IdInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: IdInput!) {
                    userWorkplace {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getUserWorkplaces(input: GridInput) {
        return this.query<OkObject>(
            `
                query($input: GridInput!) {
                    userWorkplace {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${userWorkplaceFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getUserExperiences(input: { input: GridInput, profile: boolean }) {
        return this.query(
            `
                query($input: GridInput) {
                    userExperience {
                        ${input.profile ? 'userProfileFilter' : 'filter'}(input: $input) {
                            ${filterFrag}
                            items {
                                ${userExperienceItemFrag}
                            }
                        }
                    }
                }
            `,
            { input: input.input },
        );
    }

    public createUserExperience(input: { input: UserExperienceCreateInput, profile: boolean }) {
        return this.mutate(
            `
                mutation($input: UserExperienceCreateInput!) {
                    userExperience {
                        ${input.profile ? 'userProfileCreate' : 'create'}(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input: input.input },
        );
    }

    public updateUserExperience(input: { input: UserExperienceUpdateInput, profile: boolean }) {
        return this.mutate(
            `
                mutation($input: UserExperienceUpdateInput!) {
                    userExperience {
                        ${input.profile ? 'userProfileUpdate' : 'update'}(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input: input.input },
        );
    }

    public deleteUserExperience(input: { input: ProfileIdInput, profile: boolean }) {
        return this.mutate(
            `
                mutation($input: ProfileIdInput!) {
                    userExperience {
                        ${input.profile ? 'userProfileDelete' : 'delete'}(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input: input.input },
        );
    }

}
